import { useCallback, useEffect, useState } from "react";
import CategorysServices from "../../../../../services/categorys/CategorysServices";

const useCategorys = () => {
  const {
    getAllCategorys,
    CreateCategory,
    removeCategory,
    updateCategory,
    getCategoryById,
  } = CategorysServices();
  const [dataCategorys, setDataCategorys] = useState([]);

  const getCategorys = useCallback(async () => {
    const { data } = await getAllCategorys();
    setDataCategorys(data);
  }, []);

  const onAddCategory = async (data) => {
    await CreateCategory(data);
    getCategorys();
  };

  const getCategory = useCallback(async () => {
    const { data } = await getCategoryById();
    setDataCategorys(data);
  }, []);

  const editCategory = async (id, data) => {
    const idCategory = id;
    const dataCategory = {
      name: data.name,
    };
    await updateCategory(idCategory, dataCategory);
    getCategorys();
  };

  const DeleteCategory = async (data) => {
    await removeCategory(data._id);
    getAllCategorys();
  };

  useEffect(() => {
    getCategorys();
  }, []);

  return {
    getCategorys,
    dataCategorys,
    onAddCategory,
    DeleteCategory,
    editCategory,
    getCategory,
  };
};

export default useCategorys;
