import React, { createContext, useEffect, useState } from "react";
import LoginServices from "../../../services/Login/LoginServices";
import axios from "axios";
import baseUrl from "../../../utils/apis/BaseUrl";
import API from "../../../utils/apis";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [dataUser, setDataUser] = useState({});
  const { authenticateUser } = LoginServices();

  const login = async (data) => {
    setIsLoading(true);
    try {
      const { dataUser } = await authenticateUser(data);
      localStorage.setItem("token", dataUser.data.token);
      setUser(dataUser);
      window.location.reload();
      return dataUser;
    } catch (err) {
      setError(err.message || "Error desconocido");
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      setUser(null);
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } catch (err) {
      setError(err.message || "Error al cerrar sesión");
    } finally {
      setIsLoading(false);
    }
  };

  const checkSession = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return null;
    }

    try {
      const response = await axios.post(
        `${baseUrl}${API.auth.root}${API.auth.verifySession}`,
        { token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      setDataUser(response);

      return response.data;
    } catch (error) {
      console.error("Error checking session:", error);
      return null;
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{ login, logout, isLoading, error, user, checkSession, dataUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
