import React from "react";
import PropType from "prop-types";
import { Trash2 } from "react-feather";
import { ButtonRemoveProductCart, ContentButtonDelete } from "./styled";

const ButtonDeleteProductCart = ({ handleDelete, idItem }) => {
  return (
    <>
      <ContentButtonDelete>
        <ButtonRemoveProductCart
          size="small"
          icon={<Trash2 size={16} />}
          onClick={() => handleDelete(idItem)}
        />
      </ContentButtonDelete>
    </>
  );
};

export default ButtonDeleteProductCart;

ButtonDeleteProductCart.propType = {
  handleDelete: PropType.func,
};
