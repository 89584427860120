// Footer.js
import React from "react";
import { ContentIcon, ContentInfo, FooterContainer } from "./style";
import {
  WhatsAppOutlined,
  InstagramOutlined,
  PinterestOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";

const FooterCustom = () => {
  return (
    <>
      <Col xs={24} md={24}>
        <FooterContainer>
          <Col xs={24} md={24}>
            <Row justify={"center"}>
              <Col xs={24} md={8}>
                <Row justify={"center"} gutter={20}>
                  <ContentIcon md={8} xs={8}>
                    <a
                      href="https://api.whatsapp.com/send?phone=573246097414&text=Bienvenid%40s%20a%20la%20l%C3%ADnea%20de%20atenci%C3%B3n%20verona%20%E2%98%80%EF%B8%8F%2C%20ser%C3%A1%20un%20gusto%20%20tomar%20tu%20pedido%E2%80%A6%20%20deseas%20ver%20el%20cat%C3%A1logo%20"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppOutlined style={{ fontSize: "40px" }} />
                    </a>
                  </ContentIcon>
                  <ContentIcon md={8} xs={8}>
                    <a
                      href="https://www.instagram.com/lenceria_verona/?hl=es"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramOutlined style={{ fontSize: "40px" }} />
                    </a>
                  </ContentIcon>{" "}
                  <ContentIcon md={8} xs={8}>
                    <a
                      href="https://www.instagram.com/lenceria_verona/?hl=es"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PinterestOutlined style={{ fontSize: "40px" }} />
                    </a>
                  </ContentIcon>{" "}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <ContentInfo>
              <Col xs={6} md={6}>
                <span>Home</span>
              </Col>
              <Col xs={6} md={6}>
                <span>Services</span>
              </Col>
              <Col xs={6} md={6}>
                <span>About</span>
              </Col>
              <Col xs={6} md={6}>
                <span>Terms</span>
              </Col>
            </ContentInfo>
          </Col>
        </FooterContainer>
      </Col>
    </>
  );
};

export default FooterCustom;
