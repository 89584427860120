import { useEffect, useState } from "react";

const useMovilSize = () => {
  const [dataWidth, setDataWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDataWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const MovilSize = dataWidth < 700;

  return { MovilSize , dataWidth};
};

export default useMovilSize;
