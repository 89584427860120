import { Menu, Modal } from "antd";
import styled from "styled-components";

const FilterMenu = styled(Menu)`
  width: 200px;
`;

const ContentSearch = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  font-family: "Baloo Tamma 2", system-ui;

  justify-content: center;
  /* border-bottom: ${(props) =>
    props.modePage ? "1px solid black" : "1px solid #f8f9f9"}; */
  padding: 30px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  button {
    justify-content: center;
    span {
      text-align: center;
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  outline: none;
  padding: 5px 10px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;

  border-bottom: ${(props) =>
    props.modePage ? "1px solid black" : "1px solid #f8f9f9"};

  font-size: ${(props) => (props.modePage ? "18px" : "25px")};
  color: ${(props) => (props.modePage ? "black" : "#f8f9f9")};
  ::placeholder {
    color: ${(props) => (props.modePage ? "black" : "#f8f9f9")};
  }
`;

const SearchIcon = styled.div`
  color: ${(props) => (props.modePage ? "black" : "#f8f9f9")};
  width: 15px;

  width: 20px;
  height: 20px;
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
const ContenSearchFirst = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

const ModalSearchStyle = styled(Modal)`
  width: 100%;
  .ant-modal-root {
    background-color: red !important;
  }
  .ant-modal-content {
    background-color: transparent;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
`;

const ContentSearchCustom = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const ContentResultSearch = styled.div``;

export {
  SearchInput,
  FilterMenu,
  ContentSearch,
  SearchIcon,
  SearchButton,
  ModalSearchStyle,
  ContentSearchCustom,
  ContentResultSearch,
  ContenSearchFirst,
};
