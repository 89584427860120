import ContainerProductsDrawer from "../../components/drawer/containerProductsdrawerCart/ContainerProductsDrawer";
const ShoppingCart = () => {


  return (
    <>
      <ContainerProductsDrawer
      />
    </>
  );
};

export default ShoppingCart;
