import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../components/products/context/AuthContext";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isLoading, dataUser } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  const hasAllowedRole = dataUser?.data?.user?.roles.some(role => allowedRoles.includes(role));

  if (!hasAllowedRole) {
    return <Navigate to="/verona" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;