import { Col, Form, Input, Row } from "antd";
import React from "react";
import { ContentLogo } from "./styled";

const FormSignin = () => {
  return (
    <>
      <Col xs={24} md={24}>
        <ContentLogo style={{ marginBottom: "50px" }}>
          <img src="https://res.cloudinary.com/doe7vtjfk/image/upload/v1696743512/lenceria/362254836_643559124148919_680712196996114dd1421_n_mnbyld.png"></img>
        </ContentLogo>
      </Col>
      <Col xs={24} md={24}>
        <Form.Item name="email">
          <Input className="field" placeholder="correo" />
        </Form.Item>
      </Col>

      <Col xs={24} md={24}>
        <Form.Item name="password">
          <Input type="password" className="field" placeholder="Contraseña" />
        </Form.Item>
      </Col>
      <Col xs={24} md={24}>
        <Row>
          <span>¿Olvidó su Contraseña?</span>
        </Row>
      </Col>
    </>
  );
};

export default FormSignin;
