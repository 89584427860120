import {
  ShoppingOutlined,
  UserOutlined,
  MenuOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import {
  StyledLogo,
  IconsContainer,
  StyledNav,
  MenuItems,
  ContentMobileNav,
  MobileNavButton,
  StyledMenuItem,
  ContentSubscribe,
} from "./styled";
import { useContext, useEffect } from "react";
import { Badge, Button, Col, Drawer, Menu, Popover, Row } from "antd";
import { useState } from "react";
import Logo from "../../components/main/Logo";
import { Link, NavLink } from "react-router-dom";
import { ProductsContext } from "../../components/products/context/ProductsContext";
import ModalFormLogin from "../../components/FormLogin/components/ModalFormLogin";
import { ContentActionsCart } from "../../components/products/components/productDetails/components/style";
import SearchCustomNav from "../../components/searchCustomNav/SearchCustomNav";
import MenuUser from "../../components/menuUser/MenuUser";
import { AuthContext } from "../../components/products/context/AuthContext";

export const Navbar = ({ styleNav, onShowModalSearch, showContenSearch }) => {
  const [visible, setVisible] = useState(false);
  const [visibleNav, setVisibleNav] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const { drawerShoppingCart, shoppingCart } = useContext(ProductsContext);
  const { cartItems } = shoppingCart;
  const [width, setWidth] = useState(window.innerWidth);
  const { toggleDrawer } = drawerShoppingCart;
  const { dataUser } = useContext(AuthContext);

  console.log(cartItems);

  const responsive = width < 632;

  const onShowModalLogin = () => {
    setShowModalLogin(true);
  };

  const onCloseModalLogin = () => {
    setShowModalLogin(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onshowModalSeacrh = () => {
    onShowModalSearch(true);
  };

  const onCloseModalSeacrh = () => {
    onShowModalSearch(false);
  };

  useScrollPosition(({ currPos }) => {
    const isScrolledDown = currPos.y > prevScrollPos;
    const isScrolledUp = currPos.y < prevScrollPos;
    const isAtTop = currPos.y === 0;

    if (isScrolledDown) {
      setVisibleNav(false);
    } else if (isScrolledUp) {
      setVisibleNav(true);
    } else if (isAtTop) {
      setVisibleNav(true);
    } else if (showContenSearch) {
      setVisibleNav(true);
    }

    setPrevScrollPos(currPos.y);
  });

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Col xs={24}>
        <StyledNav visibleNav={visibleNav} showContenSearch={showContenSearch}>
          {responsive ? (
            <>
              <Col xs={7}>
                <ContentMobileNav>
                  <MobileNavButton
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                  />
                </ContentMobileNav>
              </Col>

              <Col xs={10}>
                <StyledLogo justify="center">
                  <Link className="link" to="/verona">
                    <Logo />
                  </Link>
                </StyledLogo>
              </Col>

              <Col xs={7}>
                <IconsContainer>
                  <Col xs={1} />

                  <Col xs={6} className="icons">
                    {showContenSearch ? (
                      <CloseOutlined
                        style={{ fontSize: "18px" }}
                        onClick={onCloseModalSeacrh}
                      />
                    ) : (
                      <SearchOutlined
                        style={{ fontSize: "18px" }}
                        onClick={onshowModalSeacrh}
                      />
                    )}
                  </Col>
                  <Col xs={6} className="icons">
                    <ShoppingOutlined
                      style={{ fontSize: "18px" }}
                      onClick={toggleDrawer}
                    />{" "}
                  </Col>

                  {dataUser?.data?.user ? (
                    <Col xs={6} className="icons">
                      <Popover
                        placement="bottom"
                        title={"Daniela Adm"}
                        style={{ minHeight: "400px" }}
                        content={
                          <>
                            <MenuUser />
                          </>
                        }
                        trigger="click"
                      >
                        <UserOutlined
                          style={{ fontSize: "18px", cursor: "pointer" }}
                        />{" "}
                      </Popover>
                    </Col>
                  ) : (
                    <UserOutlined
                      style={{ fontSize: "18px" }}
                      onClick={onShowModalLogin}
                    />
                  )}
                </IconsContainer>
              </Col>
              <ModalFormLogin
                show={showModalLogin}
                onClose={onCloseModalLogin}
              />
            </>
          ) : (
            <>
              <Col xs={8}>
                <Link className="link" to="/verona">
                  <StyledLogo>
                    <Logo />
                  </StyledLogo>
                </Link>
              </Col>

              <Col xs={9}>
                <MenuItems justify="center" gutter={2}>
                  <Col xs={4} className="contentItem">
                    <NavLink className="link" to="/panel-configuration">
                      Store
                    </NavLink>
                  </Col>

                  <Col xs={5} className="contentItem">
                    <NavLink className="link" to="/products">
                      Products
                    </NavLink>
                  </Col>

                  <Col xs={4} className="contentItem">
                    <NavLink className="linkOff" to="/products">
                      Oulet
                    </NavLink>
                  </Col>
                </MenuItems>
              </Col>
              <Col xs={7}>
                <IconsContainer>
                  <Col xs={6} className="icons">
                    {showContenSearch ? (
                      <CloseOutlined
                        style={{ fontSize: "18px" }}
                        onClick={onCloseModalSeacrh}
                      />
                    ) : (
                      <SearchOutlined
                        style={{ fontSize: "18px" }}
                        onClick={onshowModalSeacrh}
                      />
                    )}
                  </Col>
                  <Col xs={6} className="icons">
                    <Badge
                      count={cartItems.length}
                      style={{ backgroundColor: "#ff4d4f" }}
                    >
                      <ShoppingOutlined
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        onClick={toggleDrawer}
                      />
                    </Badge>
                  </Col>
                  {dataUser?.data?.user ? (
                    <Col xs={6} className="icons">
                      <Popover
                        placement="bottom"
                        title={"Daniela Adm"}
                        style={{ minHeight: "400px" }}
                        content={
                          <>
                            <MenuUser />
                          </>
                        }
                        trigger="click"
                      >
                        <UserOutlined
                          style={{ fontSize: "18px", cursor: "pointer" }}
                        />{" "}
                      </Popover>
                    </Col>
                  ) : (
                    <Col xs={6} className="icons">
                      {" "}
                      <UserOutlined
                        style={{ fontSize: "18px" }}
                        onClick={onShowModalLogin}
                      />{" "}
                    </Col>
                  )}
                </IconsContainer>
              </Col>

              <ModalFormLogin
                show={showModalLogin}
                onClose={onCloseModalLogin}
              />
            </>
          )}

          <Drawer
            title="Menú"
            placement="left"
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            <Menu mode="vertical" theme="light" style={{ height: "74vh" }}>
              <StyledMenuItem
                key="inicio"
                className="link"
                to="/verona"
                onClick={onClose}
              >
                Home
              </StyledMenuItem>
              <StyledMenuItem key="productos">
                <Link className="link" to="/products" onClick={onClose}>
                  Productos
                </Link>
              </StyledMenuItem>
              <StyledMenuItem key="new">
                <Link className="link" to="/products" onClick={onClose}>
                  Nueva colección
                </Link>
              </StyledMenuItem>
              <StyledMenuItem key="oulet">
                <Link className="link" to="/products" onClick={onClose}>
                  Oulet
                </Link>
              </StyledMenuItem>
            </Menu>
            <Col xs={24}>
              <ContentActionsCart gutter={30}>
                <Col xs={24}>
                  <Row className="ButtonSeeCart" onClick={onClose}>
                    <Button
                    // onClick={onHandleCart}
                    >
                      <span>Subscribirte</span>
                    </Button>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row className="" style={{ textAlign: "center" }}>
                    <span>Mantente informado sobre nuestras promociones</span>
                  </Row>
                </Col>
              </ContentActionsCart>{" "}
            </Col>
          </Drawer>
        </StyledNav>
      </Col>
    </>
  );
};
