import { Col, Form, Row } from "antd";
import React, { useContext } from "react";
import { ContentForm } from "./styled";
import ButtonCustom from "../../buttonsCustom/ButtonCustom";
import FormSignin from "./FormSignin";
import { AuthContext } from "../../products/context/AuthContext";

const ContentFormLogin = () => {
  const { login } = useContext(AuthContext);
  const [form] = Form.useForm();

  const onLogUser = () => {
    const data = form.getFieldsValue();
    login(data);
  };

  return (
    <>
      <Col xs={24} md={24}>
        <Form form={form} layout="vertical">
          <ContentForm>
            <Col xs={20} md={20}>
              <Row>
                <FormSignin />

                <Col xs={24} md={24}>
                  <Row>
                    <ButtonCustom
                      textBton={"Login"}
                      className="custom"
                      onChange={onLogUser}
                    />
                  </Row>
                </Col>
                <Col xs={24} md={24}>
                  <Row>
                    <ButtonCustom
                      textBton={"Registrate ahora"}
                      className="register"
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </ContentForm>
        </Form>
      </Col>
    </>
  );
};

export default ContentFormLogin;
