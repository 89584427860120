import React, { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../context/ProductsContext";
import { Sliders } from "react-feather";
import {
  HeartOutlined,
  FilterOutlined,
  // EyeOutlined,
} from "@ant-design/icons";
import {
  ButtonsContainer,
  CardProduct,
  ContenCustom,
  ContentProducts,
  ImageContainer,
  ItemName,
  ItemPrice,
} from "./style/styleProducts";
import { useNavigate } from "react-router-dom";
import ButtonAddToCart from "../../buttonsCustom/ButtonAddToCart";
import { Button, Col, Row } from "antd";
import {
  ButtonSize,
  ContainerSizes,
  ContentInitialSizes,
  ContentNamePiece,
  TextNameSelectPiece,
} from "./style";
import useMovilSize from "../../../hooks/useMovilSize";
import SearchCustom from "../../searchCustom/SearchCustom";
import DrawerFilter from "../../drawerFilter/DrawerFilter";

const Products = ({ products, searchMode = false }) => {
  const { shoppingCart } = useContext(ProductsContext);
  const { onAddToCart } = shoppingCart;
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [visiblePiece, setVisiblePiece] = useState(0);
  const { dataWidth } = useMovilSize();
  const uploadsPath = process.env.REACT_APP_IMAGES_URL;

  const [captureProduct, setCaptureProduct] = useState({
    product: {},
    selectedPiece: [],
  });
  const navigate = useNavigate();
  const [isHoverId, setIsHoverId] = useState();

  const onCaptureProduct = (product) => {
    setCaptureProduct({ product: {}, selectedPiece: [] });
    setVisiblePiece(0);
    setCaptureProduct((prevState) => ({
      ...prevState,
      product: product,
    }));
  };

  const onShowModalFilter = () => {
    setShowModalFilter(true);
  };

  const onCloseDrawer = () => {
    setShowModalFilter(false);
  };

  const onCaptureSizes = (piece, size) => {
    setVisiblePiece(+1);

    setCaptureProduct((prevState) => ({
      ...prevState,
      selectedPiece: [...prevState.selectedPiece, { piece, size }],
    }));
  };

  const handleMouseEnter = (productId) => {
    setIsHoverId(productId);
  };

  const handleMouseLeave = () => {
    setIsHoverId(null);
  };

  useEffect(() => {
    if (
      captureProduct?.product?.pieces?.length ===
      captureProduct?.selectedPiece?.length
    ) {
      const newProduct = {
        ...captureProduct.product,
        selectedPiece: captureProduct.selectedPiece,
      };
      onAddToCart(newProduct);
      setCaptureProduct({ product: {}, selectedPiece: [] });
      setVisiblePiece(0);
    }
    return;
  }, [captureProduct]);

    const redirectToProductDetails = (productId) => {
      console.log(productId);
      navigate(`/product/${productId}`);
    };

  return (
    <>
      <ContenCustom justify="center">
        <Col xs={24} md={dataWidth < 1100 ? 24 : dataWidth < 1150 ? 24 : 18}>
          <ContentProducts justify="center" gutter={15}>
            {searchMode ? (
              <Col xs={24} md={24}>
                <Row style={{ justifyContent: "center" }}>
                  <SearchCustom modePage />
                </Row>
              </Col>
            ) : null}
            <Col xs={24} md={24}>
              {searchMode && dataWidth < 1000 ? (
                <>
                  <Row justify={"end"}>
                    <Col>
                      <Button
                        onClick={onShowModalFilter}
                        className="btnFilter"
                        icon={<Sliders size={18} />}
                      />
                    </Col>
                  </Row>
                  <DrawerFilter
                    show={showModalFilter}
                    onChange={onCloseDrawer}
                  />
                </>
              ) : null}
            </Col>

            {products.map((product) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={
                    searchMode && dataWidth > 730 ? 8 : dataWidth < 995 ? 8 : 6
                  }
                >
                  <CardProduct
                    key={product._id}
                    justify="center"
                    responsive={dataWidth < 480}
                  >
                    <ImageContainer
                      onMouseEnter={() => handleMouseEnter(product._id)}
                      onMouseLeave={handleMouseLeave}
                      key={product._id}
                      responsive={dataWidth < 480}
                    >
                      <img
                        src={
                          isHoverId === product._id &&
                          product.imgs &&
                          product.imgs[1]
                            ? `${uploadsPath}${product?.imgs[1]?.url}`
                            : `${uploadsPath}${
                                product.imgs &&
                                product.imgs[0] &&
                                product?.imgs[0]?.url
                              }`
                        }
                        alt={product.description}
                        onClick={() => redirectToProductDetails(product._id)}
                      />
                    </ImageContainer>

                    {captureProduct.product?._id === product._id ? (
                      <ContainerSizes>
                        <Col xs={24}>
                          {product?.pieces?.map((piece, index) => {
                            if (index === visiblePiece) {
                              return (
                                <ContentInitialSizes key={index}>
                                  <ContentNamePiece xs={24}>
                                    <TextNameSelectPiece justify="center">
                                      <Col xs={24}>
                                        <span>{piece.piece}</span>
                                      </Col>
                                    </TextNameSelectPiece>
                                  </ContentNamePiece>
                                  <Col xs={24}>
                                    <Row gutter={3} justify="center">
                                      {piece.sizes.map((size) => {
                                        return (
                                          <Col xs={6}>
                                            <Row>
                                              <ButtonSize
                                                disabled={size.inventory <= 0}
                                                onClick={() =>
                                                  onCaptureSizes(piece, size)
                                                }
                                              >
                                                <span>{size.name}</span>
                                              </ButtonSize>
                                            </Row>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                </ContentInitialSizes>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Col>
                      </ContainerSizes>
                    ) : null}

                    <ButtonsContainer>
                      <Button>
                        <HeartOutlined style={{ fontSize: "18px" }} />
                      </Button>
                      {/* <Button>
            <EyeOutlined style={{ fontSize: "18px" }} />
          </Button> */}
                    </ButtonsContainer>
                    {captureProduct.product?._id === product._id ? null : (
                      <>
                        {/* <VeronaText>Verona</VeronaText> */}
                        <ItemName>{product.name}</ItemName>
                        <ItemPrice>${product.price.toFixed(2)} COP</ItemPrice>
                        <ButtonAddToCart
                          onChange={() => onCaptureProduct(product)}
                        />
                      </>
                    )}
                  </CardProduct>
                </Col>
              );
            })}
          </ContentProducts>
        </Col>
      </ContenCustom>
    </>
  );
};

export default Products;
