import { useState, useCallback, useEffect } from "react";
import ProductsService from "../../../services/productsServices/Productservices";

const useProducts = () => {
  const [products, setproducts] = useState([]);
  const {
    createProduct,
    getAllProducts,
    getProduct,
    deleteProduct,
    editProduct,
  } = ProductsService();

  const getData = useCallback(
    async (filterValues) => {
      try {
        const { data } = await getAllProducts(filterValues ? filterValues : {});
        setproducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
      }
    },
    [getAllProducts]
  );

  const getProductById = async (idProduct) => {
    console.log(idProduct)
    const { data } = await getProduct(idProduct);
    console.log(data);
    return data;
  };

  const addProduct = async (formData) => {
    console.log(formData);
    await createProduct(formData);
    getData();
  };

  const updateProduct = async (idProduct, formData) => {
    try {
      console.log(formData);
      const data = await editProduct(idProduct, formData);
      console.log(data);
      getData();
    } catch (error) {
      console.error("Error update product", error);
    }
  };

  const removeProduct = async (id) => {
    await deleteProduct(id);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    products,
    getData,
    getProductById,
    addProduct,
    removeProduct,
    updateProduct,
  };
};

export default useProducts;
