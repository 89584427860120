import { Modal } from "antd";
import React from "react";
import ContentFormLogin from "./ContentFormLogin";

const ModalFormLogin = ({ show, onClose }) => {
  return (
    <>
  
      <Modal open={show} onCancel={onClose} footer={null}>
        <ContentFormLogin />
      </Modal>
    </>
  );
};

export default ModalFormLogin;
