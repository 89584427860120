import { Col, Row } from "antd";
import styled from "styled-components";

const ContentInitialProducts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 90%;
  margin: auto;
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  /* min-width: 768px; */
`;

const TitleFrontPage = styled.span`
  /* font-family: "Baloo Tamma 2", system-ui; */
  font-weight: 750;
  /* font-size: 3rem; */
  font-size: ${(props) => (props.MovilSize ? "2rem" : "2rem")};

  font-family: "AktivGrotesk Bold", system-ui;
`;

const ContentTitleFrontPage = styled(Row)`
  background-color: aqua;
`;

const ColTitleFrontPage = styled(Col)`
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  text-align: center;
`;

const ContentImgPort = styled(Row)`
  width: 100%;
  max-height: 500px;
  img {
    width: 100%;
    height: 500px;
  }
`;

const ContentActionsCruds = styled(Row)`
  margin-bottom: 20px;
  
  justify-content: end;
`;

export {
  ContentInitialProducts,
  Main,
  TitleFrontPage,
  ContentTitleFrontPage,
  ColTitleFrontPage,
  ContentImgPort,
  ContentActionsCruds,
};
