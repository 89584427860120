import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const referencesServices = () => {
  const getAllReferences = async () => {
    const references = await axios.get(`${baseUrl}${API.references.root}`);
    return {
      data: references.data,
    };
  };

  const getReferenceById = async (id) => {
    const references = await axios.get(`${baseUrl}${API.references.root}${id}`);
    return {
      data: references.data,
    };
  };

  const createreference = async (data) => {
    const references = await axios.post(
      `${baseUrl}${API.references.root}`,
      data
    );
    return {
      data: references.data,
    };
  };

  const updateReference = async (id, data) => {
    const references = await axios.put(
      `${baseUrl}${API.references.root}${id}`,
      data
    );
    return {
      data: references.data,
    };
  };

  const removeReference = async (id) => {
    console.log(id);
    const references = await axios.delete(
      `${baseUrl}${API.references.root}${id}`
    );
    return {
      data: references.data,
    };
  };

  return {
    getAllReferences,
    getReferenceById,
    removeReference,
    updateReference,
    createreference,
  };
};

export default referencesServices;
