import React from "react";
import ProductsFilterPanel from "../productsFilterPanel/ProductsFilterPanel";
import { Drawer, Menu } from "antd";

const DrawerFilter = ({ show, onChange }) => {
  return (
    <>
      <Drawer
        title="Filtros"
        placement="left"
        closable={true}
        onClose={onChange}
        visible={show}
      >
        <Menu mode="vertical" theme="light">
          <ProductsFilterPanel />
        </Menu>
      </Drawer>
    </>
  );
};

export default DrawerFilter;
