import { Col, Row } from "antd";
import { BtnCount } from "../buttonsDetails/style";
import PropType from "prop-types";
import { ContainerCount } from "../../../../itemsCartList/style";

const Counter = ({
  onChangeCountItem,
  data,
  initialValue = 1,
  addValue = 1,
  removeValue = 1,
}) => {
  return (
    
      <ContainerCount>
        <Col xs={9} md={9}>
          <BtnCount
            onClick={() =>
              onChangeCountItem({ data, add: false, value: addValue })
            }
          >
            -
          </BtnCount>
        </Col>
        <Col xs={6} md={6}>
         
            <span>{data.count ? data.count : initialValue}</span>
        </Col>
        <Col xs={9} md={9}>
          <BtnCount
            onClick={() =>
              onChangeCountItem({ data, add: true, value: removeValue })
            }
          >
            +
          </BtnCount>
        </Col>
      </ContainerCount>
  );
};

export default Counter;

Counter.propType = {
  initialValue: PropType.string,
  addValue: PropType.number,
  removeValue: PropType.number,
  min: PropType.number,
  max: PropType.number,
  data: PropType.object,
};
