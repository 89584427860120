import PropType from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import { ContentSearch, SearchButton, SearchInput } from "./styled";

import { useContext, useState } from "react";
import { ProductsContext } from "../products/context/ProductsContext";

const SearchCustom = ({ modePage }) => {
  const { searchCustom } = useContext(ProductsContext);
  const [terms, setTerms] = useState();
  const { getProductsFiltered } = searchCustom;

  const onChangeSearch = (value) => {
    const term = value.target.value;
    setTerms(term);
    getProductsFiltered({
      search: term,
    });
  };

  return (
    <>
      <ContentSearch className="modalSearch">
        <SearchInput
          type="text"
          placeholder="Buscar..."
          value={terms}
          onChange={onChangeSearch}
          modePage={modePage}
        />
        <SearchButton>
          <SearchOutlined
            style={{
              fontSize: modePage ? "14px" : "18px",
              color: modePage ? "black" : "#f8f9f9",
            }}
          />
        </SearchButton>
      </ContentSearch>
    </>
  );
};

export default SearchCustom;

SearchCustom.propType = {
  onClose: PropType.func,
};
