import { useEffect, useState } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  CarouselControl,
  CarouselInner,
  CarouselItem,
  CarouselWrapper,
  NameTex,
} from "../styled";
import { Col, Row } from "antd";
import useMovilSize from "../../../hooks/useMovilSize";

const CarouselProductsSearch = ({
  items,
  autoPlay = false,
  autoPlayTime = 3000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;
  const totalItems = items?.length;
  const { MovilSize } = useMovilSize();
  const uploadsPath = process.env.REACT_APP_IMAGES_URL;

  console.log(items);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % totalItems);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 2 + totalItems) % totalItems);
  };

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(nextSlide, autoPlayTime);
      return () => clearInterval(interval);
    }
  }, [autoPlay, autoPlayTime]);

  return (
    <CarouselWrapper>
      <CarouselInner
        style={{
          transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
          width: `${(totalItems * 20) / itemsToShow}%`,
        }}
      >
        {items?.map((item, index) => (
          <CarouselItem key={item._id} itemsToShow={itemsToShow}>
            <Row>
              <Col xs={24} md={24}>
                <img
                  src={`${uploadsPath}${item.imgs[0]?.url}`}
                  alt={item.description}
                />
              </Col>

              <Col xs={24} md={24}>
                <NameTex>{item.name}</NameTex>
              </Col>
            </Row>
          </CarouselItem>
        ))}
      </CarouselInner>
      <CarouselControl prev onClick={prevSlide}>
        <LeftOutlined />
      </CarouselControl>
      <CarouselControl next onClick={nextSlide}>
        <RightOutlined />
      </CarouselControl>
    </CarouselWrapper>
  );
};

export default CarouselProductsSearch;
