const API = {
  users: {
    root: "/users/",
  },
  products: {
    root: /products/,
  },
  sliders: {
    root: /sliders/,
  },
  categorys: {
    root: /categorys/,
  },
  garmentType: {
    root: /garmentType/,
  },
  references: {
    root: /references/,
  },
  orders: {
    root: /orders/,
  },
  payment: {
    root: /payment/,

  },

  
  auth: {
    root: /auth/,
    signin: "signin",
    verifySession: "verifySession",
  },
};

export default API;
