import React from "react";
import { ContentLogo } from "./style";

const Logo = () => {
  return (
    <ContentLogo>
      <img src="https://res.cloudinary.com/doe7vtjfk/image/upload/v1696743512/lenceria/362254836_643559124148919_680712196996114dd1421_n_mnbyld.png"></img>
    </ContentLogo>
  );
};

export default Logo;
