import { Row, Select } from "antd";
import styled from "styled-components";

const StyleSelectSize = styled(Select)`
  width: 100px;
  margin-bottom: 0px;
`;

const ContentSelect = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 50%;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
`;

const ContainerCount = styled(Row)`
  width: 100%;
  display: flex;
  border-radius: 5px;
  border: 1px solid;
  width: 35%;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  border: ${(props) => (props.counterCart ? "none" : "1px solid")};
  font-family: "Baloo Tamma 2", system-ui;

  span {
    font-family: "Baloo Tamma 2", system-ui;
  }

  div {
    display: ${(props) => (props.counterCart ? "flex" : "")};
    gap: ${(props) => (props.counterCart ? "10px" : "2px")};
    align-items: center;
    div {
    }
  }
`;

const TextParcial = styled.span`
  color: #919191;
  font-family: "Baloo Tamma 2", system-ui;
`;

const PriceItemTotal = styled(Row)`
  color: #919191;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Baloo Tamma 2", system-ui;
`;

const ContentCount = styled(Row)`
  margin-top: 20px;
`;

const ContainerCountPrev = styled(Row)`
  width: 100%;

  border: ${(props) => (props.counterCart ? "none" : "1px solid")};
  font-family: "Baloo Tamma 2", system-ui;

  span {
    font-family: "Baloo Tamma 2", system-ui;
  }

  div {
    display: ${(props) => (props.counterCart ? "flex" : "")};
    gap: ${(props) => (props.counterCart ? "10px" : "2px")};
    align-items: center;
    div {
    }
  }
`;

const ContentDetailsShoppingCart = styled(Row)`
  margin-top: 80px;
  display: flex;
`;

const ContentSummary = styled(Row)`
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  span {
    font-family: "Baloo Tamma 2", system-ui;
    font-size: 1.4rem;
  }
`;

const ContentProductsParcial = styled(Row)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  font-family: "Baloo Tamma 2", system-ui;

  span {
    font-size: 1rem;
    font-weight: bold;

    font-family: "Baloo Tamma 2", system-ui;
  }
`;

const ContentParcial = styled(Row)`
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  font-family: "Baloo Tamma 2", system-ui;

  span {
    font-size: 1rem;
    font-weight: bold;

    font-family: "Baloo Tamma 2", system-ui;
  }
  justify-content: end;
`;

const ContentActionsCart = styled(Row)`
  padding: 0.5rem;
  display: flex;

  .ButtonBuy {
    min-height: 3rem !important;
    button {
      margin-bottom: 20px;
      width: 100%;
      min-height: 3rem !important;
      font-family: "Baloo Tamma 2", system-ui;
      :hover {
        background: #f8f9fa;
        color: black !important;
        border: 1px solid black !important;
      }
    }
    .parcial {
      justify-content: end;
    }
  }
  .ButtonSeeCart {
    min-height: 3rem !important;
    background-color: black;
    border-radius: 10px;
    button {
      width: 100%;
      background-color: black;

      min-height: 3rem !important;
      font-family: "Baloo Tamma 2", system-ui;
      color: white;
      :hover {
        background: #dee2e6;
        color: black !important;
        border: 1px solid white !important;
      }
    }
  }
`;

export {
  StyleSelectSize,
  ContentSelect,
  ContainerCount,
  PriceItemTotal,
  TextParcial,
  ContentCount,
  ContentActionsCart,
  ContentDetailsShoppingCart,
  ContainerCountPrev,
  ContentSummary,
  ContentProductsParcial,
  ContentParcial,
};
