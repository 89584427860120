import PropType from "prop-types";
import React from "react";
import { ButtonCustomStyle } from "./style";

const ButtonCustom = ({ textBton, onChange, className = "" }) => {
  return (
    <>
      <ButtonCustomStyle onClick={onChange} className={className}>
        {textBton}
      </ButtonCustomStyle>
    </>
  );
};

ButtonCustom.propType = {
  textBton: PropType.string,
  className: PropType.string,
  onChange: PropType.func,
};

export default ButtonCustom;
