import axios from "axios";
import API from "../../utils/apis";
import baseUrl from "../../utils/apis/BaseUrl";

const ProductsService = () => {
  const getAllProducts = async ({ search, category, reference, color }) => {
    const url = `${baseUrl}${API.products.root}`;

    const params = {
      search,
      category,
      reference,
      color,
    };

    const getProducts = await axios.get(url, {
      params: params,
    });

    return {
      data: getProducts.data,
    };
  };

  const createProduct = async (product) => {
    console.log(product);
    const formData = new FormData();

    formData.append("name", product.name);
    formData.append("category", product.category);
    formData.append("reference", product.reference);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("stock", product.stock);
    formData.append("discountPercentage", product.discountPercentage);
    formData.append("garmentType", product.garmentType);
    formData.append("pieces", JSON.stringify(product.pieces));
    product.imgs.forEach((img) => {
      formData.append("imgs", img);
    });

    await axios.post(`${baseUrl}${API.products.root}`, formData);
  };

  // const getProduct = async (id) => {
  //   console.log(id);
  //   const getProduct = await axios.get(
  //     "https://api.lenceriaverona.com/api" + id
  //   );
  //   return {
  //     data: getProduct.data,
  //   };
  // };

  const getProduct = async (id) => {
    console.log(id);
    try {
      const response = await axios.get(`${baseUrl}${API.products.root}${id}`);
      console.log(response);
      return {
        data: response.data,
      };
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error; // Re-throw the error to handle it where the function is called
    }
  };

  const editProduct = async (id, product) => {
    console.log(product);
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("name", product.name);
    formData.append("category", product.category);
    formData.append("reference", product.reference);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("stock", product.stock);
    formData.append("discountPercentage", product.discountPercentage);
    formData.append("garmentType", product.garmentType);
    formData.append("pieces", JSON.stringify(product.pieces));
    product.imgs.forEach((img) => {
      if (img instanceof File) {
        formData.append("imgs", img);
      }
    });

    product.imgs.forEach((img) => {
      if (typeof img._id === "string") {
        formData.append("existingImgs", img._id);
      }
    });

    const { data } = await axios.put(
      `${baseUrl}${API.products.root}${id}`,
      formData
    );

    return { data };
  };

  const deleteProduct = async (id) => {
    console.log(id);
    await axios.delete(`${baseUrl}${API.products.root}${id}`);
  };

  return {
    getAllProducts,
    deleteProduct,
    editProduct,
    getProduct,
    createProduct,
  };
};
export default ProductsService;
