import { useCallback, useEffect, useState } from "react";
import ProductsService from "../../../services/productsServices/Productservices";

const useSearchCustom = () => {
  const [productsFiltered, setProductsFiltered] = useState([]);
  const { getAllProducts } = ProductsService();

  const getProductsFiltered = useCallback(
    async (filterValues) => {
      try {
        const { data } = await getAllProducts(filterValues ? filterValues : {});
        setProductsFiltered(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    [getAllProducts]
  );

  useEffect(() => {
    getProductsFiltered({});
  }, []);

  return {
    productsFiltered,
    getProductsFiltered,
  };
};

export default useSearchCustom;
