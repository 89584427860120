import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const orderServices = () => {
  const createOrder = async (data) => {
    const dataOrder = await axios.post(`${baseUrl}${API.orders.root}`, data);
    return {
      dataOrder,
    };
  };


  const getAllOrders = async () => {
    const response = await axios.get(`${baseUrl}${API.orders.root}`);
    return response.data;
  };

  return {
    createOrder,
    getAllOrders,
  };
};

export default orderServices;
