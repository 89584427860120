import { Button } from "antd";
import styled from "styled-components";

const BtnCount = styled(Button)`
display: flex;

border: none;
`;

export { BtnCount };
