import React from "react";
import AppRouter from "./Router/AppRouter";
import { Col, Row } from "antd";

const App = () => {
  return (
    <Row className="contentInit">
      <Col xs={24} md={24} >
        <Row justify={"center"} style={{ width: "100%" }}>
          <AppRouter />
        </Row>
      </Col>
    </Row>
  );
};

export default App;
