import { Drawer } from "antd";
import styled from "styled-components";

const DrawerCartStyle = styled(Drawer)`
  
  background: red;
  border-top-left-radius: 2px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
`;

export { DrawerCartStyle };
