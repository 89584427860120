import { Button, Row } from "antd";
import styled from "styled-components";

const ContentActionsTableProducts = styled.div`
  display: flex;
  gap: 20px;
`;

const ButtonAddPoduct = styled(Button)`
  background: #e6f2c3;
`;

const ButtonUpdateProduct = styled(Button)`
  background: #c3f2e7;
`;

const ButtonRemoveProduct = styled(Button)`
  background: #f3ddd6;
`;

const ButtonRemoveProductCart = styled(Button)`
  background-color: transparent !important;
  color:#8C8C8C;
  border: none !important;
  :hover {
    color: red !important;
  }
`;

const ContentButtonDelete = styled(Row)`
  margin-left: 15px;
`;

export {
  ButtonAddPoduct,
  ButtonUpdateProduct,
  ButtonRemoveProduct,
  ContentActionsTableProducts,
  ButtonRemoveProductCart,
  ContentButtonDelete,
};
