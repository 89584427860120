import { useCallback, useEffect, useState } from "react";
import orderServices from "../../../services/orders/orderServices";

const useOrders = () => {
  const { getAllOrders } = orderServices();
  const [isLoading, setIsLoading] = useState();
  const [dataOrders, setDataOrders] = useState([]);


  const getOrdes = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getAllOrders();
      setDataOrders(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getOrdes();
  }, []);

  return {
    isLoading,
    dataOrders,
  };
};

export default useOrders;
