import { Row } from "antd";
import styled from "styled-components";

const ContentSearchNav = styled(Row)`
  margin-top: 97px;
  min-height: 350px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding-top: 20px;
  padding-bottom: 20px;

  justify-self: center;
  background-color: white;
  opacity: 0.9;
  .ant-drawer-mask {
    border: none;
  }
`;

const ContentSearchModal = styled(Row)`
  margin-bottom: 20px;
  width: 100%;
  .modalSearch{

  padding-top: 0px;
  }
`;

export { ContentSearchNav, ContentSearchModal };
