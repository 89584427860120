import React, { useContext } from "react";
import PropType from "prop-types";
import { ContentShoppingCart, ContentTotal, Scrollbar } from "./style";
import { ContentActionsCart } from "../../products/components/productDetails/components/style";
import { Button, Col, Row } from "antd";
import ListItems from "../../itemsCartList/ListItems";
import { ProductsContext } from "../../products/context/ProductsContext";
import { useNavigate } from "react-router-dom";

const ContainerProductsDrawer = () => {
  const { shoppingCart, drawerShoppingCart } = useContext(ProductsContext);
  const { totalPrice } = shoppingCart;
  const { closeDrawer } = drawerShoppingCart;
  const Navigate = useNavigate();

  const onHandlePayment = () => {
    Navigate("/dataBuy");
    closeDrawer();
  };

  const onHandleCart = () => {
    Navigate("/cart");
    closeDrawer();
  };

  console.log(shoppingCart);

  return (
    <>
      <ContentShoppingCart>
        <Scrollbar>
          <ListItems />
        </Scrollbar>
        <>
          <Col xs={24}>
            <ContentTotal justify="end" gutter={8}>
              <Col>
                <Row>
                  <span>Total: </span>
                </Row>{" "}
              </Col>
              <Col>
                <Row>
                  <span> ${totalPrice}</span>{" "}
                </Row>
              </Col>
            </ContentTotal>
          </Col>
          <Col xs={24}>
            <ContentActionsCart gutter={30}>
              <Col xs={12}>
                <Row className="ButtonBuy">
                  <Button onClick={onHandlePayment}>
                    <span>Compra Rapida</span>
                  </Button>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="ButtonSeeCart">
                  <Button onClick={onHandleCart}>
                    <span>Ver Carrito </span>
                  </Button>
                </Row>
              </Col>
            </ContentActionsCart>{" "}
          </Col>
        </>
      </ContentShoppingCart>
    </>
  );
};
export default ContainerProductsDrawer;

ContainerProductsDrawer.propType = {
  priceTotalItem: PropType.func,
  cartItems: PropType.array,
  updatePriceItem: PropType.func,
};
