import React from "react";
import { ContentSliderVideo } from "./style";
import { Col } from "antd";

const Slider = () => {
  const video = {
    url: "https://res.cloudinary.com/doe7vtjfk/video/upload/v1696721762/B1B4806E-9DCC-45F6-AA44-15D8F02E04D5_b2za6u.mov",
  };

  return (
    <Col xs={24} md={24}>
      <ContentSliderVideo justify="center">
        <video
          src={video.url}
          style={{ objectFit: "cover" }}
          autoPlay
          controls={false}
          muted
          loop
        />
      </ContentSliderVideo>
    </Col>
  );
};

export default Slider;
