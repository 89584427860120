import { Col, Row } from "antd";
import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const MenuUser = () => {
  return (
    <>
      <Col xs={24} md={24}>
        <Row>
          <NavLink className="link" to="/panel-configuration">
            <Row>
              <Col xs={6}>
                <SettingOutlined style={{ fontSize: "14px" }} />{" "}
              </Col>
              <Col xs={18}>Configuración</Col>
            </Row>
          </NavLink>
        </Row>
      </Col>
    </>
  );
};

export default MenuUser;
