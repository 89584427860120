import "react-modern-drawer/dist/index.css";
import { ProductsContext } from "../products/context/ProductsContext";
import { useContext } from "react";
import { DrawerCartStyle } from "./style";
import {} from "../../cart/components/ShoppingCart";
import ShoppingCart from "../../cart/components/ShoppingCart";
const DrawerCart = () => {
  const { drawerShoppingCart } = useContext(ProductsContext);
  const { isOpen, closeDrawer } = drawerShoppingCart;

  return (
    <>
      <DrawerCartStyle
        title="Shopping Cart"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={isOpen}
        style={{ background: "#f8f9fa" }}
      >
        <ShoppingCart />
      </DrawerCartStyle>
    </>
  );
};

export default DrawerCart;
