import axios from "axios";
import baseUrl from "../../utils/apis/BaseUrl";
import API from "../../utils/apis";

const LoginServices = () => {
  const authenticateUser = async (data) => {
    const dataUser = await axios.post(
      `${baseUrl}${API.auth.root}${API.auth.signin}`,
      data
    );
    console.log(dataUser);
    return {
      dataUser,
    };
  };

  return {
    authenticateUser,
  };
};

export default LoginServices;
