import React, { useContext } from "react";
import FilterFields from "./FilterFields";
import { ProductsContext } from "../products/context/ProductsContext";

const ProductsFilterPanel = () => {
  const { searchCustom } = useContext(ProductsContext);
  const { productsFiltered } = searchCustom;

  const categoriesSet = new Set();
  const referencesSet = new Set();

  productsFiltered.forEach((product) => {
    product.category?.forEach((cat) => categoriesSet.add(JSON.stringify(cat)));
    if (product.reference) {
      referencesSet.add(JSON.stringify(product.reference));
    }
  });

  const categories = Array.from(categoriesSet).map((cat) => JSON.parse(cat));
  const references = Array.from(referencesSet).map((ref) => JSON.parse(ref));

  console.log(productsFiltered);

  return (
    <>
      <FilterFields categories={categories} references={references} />
    </>
  );
};

export default ProductsFilterPanel;
