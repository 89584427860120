import { Col, Row } from "antd";
import styled from "styled-components";

const FooterContainer = styled(Row)`
  /* background-color: #ffffff !important; */
  background-color: rgba(211, 211, 211, 0.5);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  min-height: 200px !important;
  justify-content: center;
  margin-top: 70px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;

`;

const ContentIcon = styled(Col)`
  justify-content: center;
  text-align: center;
  color: #75777e;
  margin-top: 30px;
  a{
    color: #75777E;
    :hover{
    color: #75777E;
opacity: 0.6;
    }
  }
`;

const ContentInfo = styled(Row)`
  justify-content: center;
  text-align: center;
  font-family: "Baloo Tamma 2", system-ui;
    font-weight: 500;


`;
export { FooterContainer, ContentIcon, ContentInfo };
