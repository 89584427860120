import { Button, Col, Row } from "antd";
import styled from "styled-components";

const ContentHeader = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .container {
    margin-right: 30px;
  }
`;

const ContainerSizes = styled(Row)`
text-align: center;

`;

const ContentInitialSizes = styled(Row)``;

const ContentNamePiece = styled(Col)`
  span {
    font-size: 0.9rem;
  }
`;

const ContentSizes = styled(Row)`
  padding: 20px;
`;
const ButtonSize = styled(Button)`
  max-width: 35px;
  justify-content: center;
  span {
    text-align: center;
    font-size: 0.8rem;
  }
`;

const TextNameSelectPiece = styled(Row)`
  margin-top: 20px;
  text-align: center;
  span {
    color: #919191;
  }
`;

const ContentFooter = styled(Row)`
  min-height: 300px;
width: 100%;

`;

export {
  ContentHeader,
  ContainerSizes,
  ButtonSize,
  ContentSizes,
  ContentInitialSizes,
  ContentNamePiece,
  TextNameSelectPiece,
  ContentFooter,
};
