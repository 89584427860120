import { useCallback, useEffect, useState } from "react";
import referencesServices from "../../../../../../services/references/referencesServices";

const useReference = () => {
  const {
    createreference,
    getAllReferences,
    getReferenceById,
    removeReference,
    updateReference,
  } = referencesServices();
  const [data, setData] = useState();

  const getReferences = useCallback(async () => {
    const { data } = await getAllReferences();
    setData(data);
  }, []);

  const onAddReference = async (data) => {
    await createreference(data);
    getReferences();
  };

  const getference = async (idReference) => {
    await getReferenceById(idReference);
    getReferences();
  };

  const editReference = async (id, data) => {
    const idReference = id;
    const dataReference = {
      name: data.name,
    };
    await updateReference(idReference, dataReference);
    getReferences();
  };

  const deleteReference = async (data) => {
    await removeReference(data._id);
    getReferences();
  };

  useEffect(() => {
    getReferences();
  }, []);

  return {
    onAddReference,
    deleteReference,
    editReference,
    getReferences,
    getference,
    data,
  };
};

export default useReference;
