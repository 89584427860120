import { Row } from "antd";
import styled from "styled-components";
const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselInner = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

const CarouselItem = styled.div`
  flex: 0 0 calc(100% / 3);
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    max-width: 100%;
    min-height: 160px;
    max-height: 160px;
    min-width: 130px;
    max-width: 130px;

    object-fit: cover;
    border-radius: 4%;
  }
  text-align: center;
`;

const NameTex = styled.span`
  font-family: "Baloo Tamma 2", system-ui;
  font-size: 0.8rem;
`;

const CarouselControl = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  color: black;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }

  ${({ prev }) =>
    prev &&
    `
        left: 10px;
    `}

  ${({ next }) =>
    next &&
    `
        right: 10px;
    `}

    @media (max-width: 768px) {
    padding: 8px;
  }
`;

const ContentCarousel = styled(Row)`
  margin-top: 60px;
  margin-bottom: 60px;

  align-self: center;
  justify-content: center;
`;

export {
  CarouselWrapper,
  CarouselInner,
  CarouselItem,
  CarouselControl,
  ContentCarousel,
  NameTex,
};
